function BottomNav(props) {
  return (
    <div
      style={{ paddingTop: "1rem", textAlign: "center", color: "lightgray" }}
    >
      <i>
        FindTune alpha 0.4.0 | Marcus Gustafsson 2021-2024
        <br />
        OBS! Denna sida är endast en förstudie för en sökmotor för folkmusik!
        <br />
        Data från folkwiki.se
      </i>
    </div>
    /* <BottomNavigation
            showLabels
        >
            <BottomNavigationAction label="Home" icon={<HomeOutlined />}></BottomNavigationAction>
            <BottomNavigationAction label="Search" icon={<SearchRounded />}></BottomNavigationAction>
        </BottomNavigation>*/
  );
}

export default BottomNav;
