import { Button } from '@material-ui/core';
import {Component} from 'react';
import { Notation } from 'react-abc';

class Tune extends Component {
    searchSimilar(tuneId) {
        this.props.searchSimilar(tuneId);
        this.props.onClose();
    }

    render() {
        const {tune} = this.props;
        return (
            <div>
                <Button color="primary" variant="contained" onClick={this.props.onClose} >Stäng</Button>
                <Button color="primary" variant="contained" onClick={() => this.searchSimilar(tune.id)} >Sök liknande</Button>
                <div>{tune.title}</div>
                <Notation 
                    notation={tune.abc} 
                    engraverParams={{
                        responsive: "resize"
                    }}
                />
            </div>

        );
    }
}

export default Tune;