import { Component } from "react";
import {
  Button,
  IconButton,
  TextField,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";

import AudioInput from "./AudioInput";
import RootSelector from "./RootSelector";

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: this.props.searchString || "",
      searchAlgorithm: "numbers-relative",
      levenshtein: true,
      fromBeginning: true,
      inputRoot: "*",
      searchRoots: ["*"],
      searchButtonEnabled: this.isSearchStringValid(this.props.searchString),
    };
    this.doSearch = this.doSearch.bind(this);
  }

  componentDidMount() {
    //this.props.doSearch(this.doSearch);
  }

  doSearch(id = null) {
    //this.setState({ state: "searching", items: null });
    if (this.state.searchString.length < 5) return;
    this.props.setState("searching");
    this.props.setItems(null);
    let searchString = "";
    if (id) {
      searchString = "id=" + id;
    } else if (!this.state.searchString.match(/[^0-9]/)) {
      searchString = "numbers=" + this.state.searchString;
    } else if (!this.state.searchString.match(/[^a-h]/)) {
      searchString = "notes=" + this.state.searchString;
    } else {
      searchString = "q=" + this.state.searchString;
    }
    fetch(
      "https://findtune-api.marcus.ax/index.php?action=search&abc=true&&ngrams=" +
        this.state.searchAlgorithm +
        "&levenshtein=" +
        (this.state.levenshtein ? "true" : "") +
        "&fromBeginning=" +
        (this.state.fromBeginning ? "true" : "") +
        "&inputRoot=" +
        this.state.inputRoot +
        "&roots=" +
        this.state.searchRoots.join(",") +
        "&" +
        searchString
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.props.setState("done");
          this.props.setItems(result.items);
          this.props.setSearchString(result.searchString);
          this.setState({
            searchString: result.searchString,
          });
        },
        (error) => {
          this.props.setState("done");
          this.props.setError(error);
        }
      );
  }

  onChangeHandler = (event) => {
    // enable search button if more than four notenumbers/names, or it's a text search
    const buttonEnabled = this.isSearchStringValid(event.target.value);
    this.props.setSearchString(event.target.value);
    this.setState({
      searchString: event.target.value,
      state: "",
      items: null,
      searchButtonEnabled: buttonEnabled,
    });
  };

  onKeyDownHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.doSearch();
    }
  };

  isSearchStringValid(value) {
    if (!value) return false;
    return value.match(/[^0-9^a-h]/) || value.length > 4;
  }

  handleChangeMethod = (event) => {
    const state = { searchAlgorithm: event.target.value };
    if (event.target.value === "") state.levenshtein = false;
    this.setState(state);
  };

  handleClearClick = (event) => {
    this.props.setSearchString("");
    this.props.setState("");
    this.props.setItems(null);
    this.setState({
      searchString: "",
      items: null,
      state: "",
      searchButtonEnabled: false,
    });
  };

  handleLevenshteinChange = (event) => {
    this.setState({ levenshtein: event.target.checked });
  };

  handleFromBeginningChange = (event) => {
    this.setState({ fromBeginning: event.target.checked });
  };

  handleAbcPreview = (event) => {
    this.props.setAbcPreview(event.target.checked);
  };

  showInputRoot = (event) => {
    this.props.setItems(null);
    this.setState({ state: "inputRoot" });
  };

  setInputRoot = (root) => {
    this.setState({ inputRoot: root, state: "" });
  };

  showSearchRoots = (event) => {
    this.props.setItems(null);
    this.setState({ state: "inputRoots" });
  };

  setSearchRoots = (roots) => {
    this.setState({ searchRoots: roots, state: "" });
  };

  handleStartRecording = (event) => {
    this.props.setItems(null);
    this.setState({
      state: "recording",
      searchAlgorithm: "numbers-relative-change",
    });
  };

  handleStopRecording = (data) => {
    let searchString = data.notes
      .map((note) => note.name.substr(0, 1))
      .join("");
    const buttonEnabled = this.isSearchStringValid(searchString);
    this.setState(
      {
        state: "",
        searchString: searchString,
        items: null,
        searchButtonEnabled: buttonEnabled,
      },
      () => this.doSearch()
    );
    //this.doSearch();
  };

  render() {
    const { state, searchAlgorithm, levenshtein, fromBeginning } = this.state;
    const mode = this.props.mode;
    let clearButton = this.state.searchString
      ? {
          endAdornment: (
            <IconButton onClick={this.handleClearClick}>
              <Clear />
            </IconButton>
          ),
        }
      : null;

    if (state === "inputRoot") {
      return (
        <RootSelector
          onClose={(root) => this.setInputRoot(root)}
          root={this.state.inputRoot}
          title="Grundton för inspelning"
        />
      );
    } else if (state === "inputRoots") {
      return (
        <RootSelector
          onClose={(roots) => this.setSearchRoots(roots)}
          roots={this.state.searchRoots}
          title="Grundton för sökning"
          description="Du kan välja flera grundtoner. Använd * för alla grundtoner."
        />
      );
    } else if (state === "recording") {
      return <AudioInput saveInput={this.handleStopRecording} />;
    } else {
      return (
        <div>
          <TextField
            onChange={this.onChangeHandler}
            onKeyDown={(e) => {
              this.onKeyDownHandler(e);
            }}
            value={this.state.searchString}
            placeholder="Melodi med siffror/tonnamn eller låttitel..."
            fullWidth={true}
            variant="outlined"
            id="search"
            type="text"
            InputProps={clearButton}
          />
          {mode === "advanced" ? (
            <FormControl component="fieldset">
              <FormLabel component="legend">Algoritm</FormLabel>
              <RadioGroup
                aria-label="ngram"
                name="ngram"
                value={searchAlgorithm}
                onChange={this.handleChangeMethod}
              >
                <FormControlLabel
                  value="numbers-absolute"
                  control={<Radio />}
                  label="N-gram"
                />
                <FormControlLabel
                  value="numbers-absolute-change"
                  control={<Radio />}
                  label="N-gram förändring"
                />
                <FormControlLabel
                  value="numbers-relative"
                  control={<Radio />}
                  label="N-gram relativt"
                />
                <FormControlLabel
                  value="numbers-relative-change"
                  control={<Radio />}
                  label="N-gram relativ förändring"
                />
                <FormControlLabel value="" control={<Radio />} label="Exakt" />
              </RadioGroup>
            </FormControl>
          ) : (
            ""
          )}
          <FormControl component="fieldset">
            {mode === "advanced" ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={levenshtein}
                    onChange={this.handleLevenshteinChange}
                    name="levenshtein"
                    color="primary"
                    disabled={searchAlgorithm === ""}
                  />
                }
                label="Levenshtein"
              />
            ) : (
              ""
            )}
            {mode !== "simple" ? (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fromBeginning}
                      onChange={this.handleFromBeginningChange}
                      name="fromBeginning"
                      color="primary"
                      disabled={searchAlgorithm === ""}
                    />
                  }
                  label="Från början"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.abcPreview}
                      onChange={this.handleAbcPreview}
                      name="abcpreview"
                      color="primary"
                    />
                  }
                  label="Förhandsvisa"
                />
              </div>
            ) : (
              ""
            )}
          </FormControl>
          <FormControl component="fieldset">
            {mode === "advanced" ? (
              <div>
                Grundton (indata):
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.showInputRoot()}
                  style={{ marginBottom: "0.5rem" }}
                >
                  {this.state.inputRoot}
                </Button>
              </div>
            ) : (
              ""
            )}

            {mode !== "simple" ? (
              <div>
                <Button
                  color={"secondary"}
                  variant="contained"
                  onClick={() => this.handleStartRecording()}
                  style={{ marginBottom: "0.5rem" }}
                >
                  Spela in
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.showSearchRoots()}
                  style={{ marginBottom: "0.5rem" }}
                >
                  Grundton: {this.state.searchRoots.join(", ")}
                </Button>
              </div>
            ) : (
              ""
            )}
          </FormControl>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.doSearch()}
            fullWidth={true}
            style={{ marginBottom: "0.5rem" }}
            disabled={!this.state.searchButtonEnabled}
          >
            Sök
          </Button>
          {!this.state.searchButtonEnabled ? (
            <div
              style={{
                textAlign: "center",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              Söksträngen måste vara minst fem toner lång
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
  }
}

export default SearchForm;
