import "./App.css";
import { Container, Box } from "@material-ui/core";
import Navbar from "./Navbar";
import Search from "./Search";
import BottomNav from "./BottomNav";
import { Component } from "react";
import AbcPreview from "./AbcPreview";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
    };
  }

  navigateTo(page) {
    this.setState({ page: page });
  }

  render() {
    let content = <Search></Search>;
    const abc = `%%abc-charset utf-8
X: 1
M: 3/4
L: 1/16
K: G
d2c2 B4 G4 | c2B2 A4 D4 | G2>F2 G2A2 B2c2 | d2de d2c2 B2c2 |
d2c2 B4 G4 | c2B2 A4 D4 | G2>F2 G2A2 B2c2 | A2F2 G8 ::
D2D2 D2D2 D2D2 | (D2E2) E2E2 E2F2 | G2>A2 B2A2 G2F2 | G4 (DE)DC B,2C2 |
D2D2 D2D2 D2D2 | (D2E2) E2E2 E2F2 | G2>A2 B2A2 G2F2 | G4 G8 :|

`;
    if (this.state.page === "help") {
      content = (
        <div>
          <h2>Hjälp</h2>
          <div>
            <p>
              Du kan söka på låttitel eller melodi. För melodi, använd siffrorna
              1-7 (där 1 är första tonen i skalan, 2 andra och så vidare) eller
              tonnamn (a-g, utan förtecken, h kan användas istället för b).
              Sökningen görs oberoende av tonart och modus.
            </p>
            <h3>Exempel</h3>
            Det här är början på låten "1814".
            <AbcPreview onClick={() => {}} abc={abc} />
            <p>
              Första tonen är ett d, andra ett c och så vidare. De två första
              takterna med tonnamn blir alltså <b>dcbgcbad</b>.
            </p>
            <p>
              I siffror blir samma melodi <b>54314325</b>. Eftersom tonarten är
              <b>G-dur</b> är tonen <b>g nummer 1</b>. Tonen d är femte tonen i
              skalan, därför börjar melodin på 5.
            </p>
            <p>
              Tredje takten blir i tonnamn 'gfgabc' och i siffror '171234'. Lägg
              märke till att man <b>inte ska skriva några förtecken</b>: det
              räcker att skriva 'f' fast det egentligen är ett 'fiss'.
            </p>
            <p>
              Oftast räcker det med att skriva in en eller två takter för att
              hitta rätt melodi.
              <b>Prova skriv in 54314325 (eller dcbgcbad) här nedan och sök!</b>
            </p>
          </div>
          <Search mode="simple"></Search>
        </div>
      );
    } else if (this.state.page === "about") {
      content = (
        <div>
          <h2>Om FindTune</h2>
          <p>
            FindTune uppkom genom mitt eget behov av att kunna söka efter
            folkmusiklåtar på melodin. Det händer ju att man har en melodi i
            huvudet men som man inte vet namnet på...
          </p>
          <p>
            Min förhoppning är att kunna vidareutveckla FindTune: att importera
            låtar från flera olika databaser samt vidareutveckla sidan så den
            blir användbar för såväl musiker som forskare.
          </p>
          <p>Du kan kontakta mig på findtune@marcus.ax.</p>
          <p style={{ textAlign: "right" }}>MarcusG</p>
        </div>
      );
    } else if (this.state.page === "advanced") {
      content = <Search mode="advanced"></Search>;
    }
    const page = this.state.page;
    return (
      <div className="app">
        <Navbar menuEvent={(page) => this.navigateTo(page)} activeMenu={page} />
        <Box pt="0.5rem">
          <Container>{content}</Container>
        </Box>
        <BottomNav></BottomNav>
      </div>
    );
  }
}

export default App;
