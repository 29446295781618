import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./Navbar.css";

const useStyles = makeStyles((theme) => ({
  buttonStyle: (props) => {
    return {
      background: (props) => (props.myProp ? "red" : "blue"),
      [theme.breakpoints.up("sm")]: {
        background: "cyan",
      },
    };
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
/*
<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <MenuIcon />
            </IconButton>
            */

function Navbar(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">FindTune</Typography>
          {/*<MenuItem key="a" onClick={() => handleMenuClick("")}>
              <Typography textAlign="center">Sök</Typography>
            </MenuItem>
            <MenuItem key="" onClick={() => handleMenuClick("help")}>
              <Typography textAlign="center">Hjälp</Typography>
            </MenuItem>
            <MenuItem key="" onClick={() => handleMenuClick("about")}>
              <Typography textAlign="center">Om</Typography>
            </MenuItem>*/}
          <div>
            <a
              onClick={() => props.menuEvent("")}
              className={props.activeMenu === "" ? "active" : ""}
              href="#search"
            >
              Sök
            </a>
            <a
              onClick={() => props.menuEvent("help")}
              className={props.activeMenu === "help" ? "active" : ""}
              href="#help"
            >
              Hjälp
            </a>
            <a
              onClick={() => props.menuEvent("advanced")}
              className={props.activeMenu === "advanced" ? "active" : ""}
              href="#advanced"
            >
              Avancerad
            </a>
            <a
              onClick={() => props.menuEvent("about")}
              className={props.activeMenu === "about" ? "active" : ""}
              href="#about"
            >
              Om
            </a>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Navbar;
