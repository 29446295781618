import { Component } from "react";
import { PitchDetector } from "pitchy";
import { Button } from "@material-ui/core";
import { Notation } from "react-abc";

class AudioInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      searchString: "",
      recording: false,
    };

    this.historyLength = 100;

    this.notes = [];
    this.subNotes = [];
    this.history = [];
    this.minClarityPercent = 95;
    [this.minPitch, this.maxPitch] = [60, 10000];

    [this.overrideSampleRate, this.desiredSampleRate, this.sampleRate] = [
      false,
      44100,
      null,
    ];
    this.inputBufferSize = 2048;

    this.holdCount = 0;
    this.minNumber = 66;
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.setUpdatePitchInterval(0);
  }

  toggleRecording() {
    if (this.state.recording) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  }

  startRecording() {
    this.setUpdatePitchInterval(50);

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      this.micStream = stream;
      this.resetAudioContext();
    });
    this.setState({ recording: true });
  }

  stopRecording() {
    this.setState({ recording: false });
    this.micStream.getAudioTracks().forEach((track) => {
      track.stop();
    });
    this.audioContext.close();
  }

  clear() {
    if (this.state.recording) this.stopRecording();
    this.setState({ searchString: "" });

    this.notes = [];
    this.subNotes = [];
    this.history = [];
  }

  close() {
    if (this.state.recording) this.stopRecording();
    /*
    const mods = [6, 1, 8, 3, 10];
    const accidentials = [0, 0, 0, 0, 0]; // f,c,g,d,a
    this.notes.forEach((note) => {
      const index = mods.indexOf(note.number % 12);
      if (index >= 0) accidentials[index]++;
    });
    const subNumbers = [0, 0, 0, 0, 0];
    this.subNotes.forEach(
      (note) => (subNumbers[note.subNumber % 5] += note.length)
    );
    let offset = 0;
    for (let i = 1; i < subNumbers.length; i++) {
      if (subNumbers[i] > subNumbers[i - 1]) offset = i;
    }
    const adjustedNotes = [];
    const noteNames = [
      "c",
      "c#",
      "d",
      "eb",
      "e",
      "f",
      "f#",
      "g",
      "g#",
      "a",
      "bb",
      "b",
    ];
    this.subNotes.forEach((subNote) => {
      const number =
        noteNames[Math.floor((subNote.subNumber - offset) / 5) % 12];
      if (
        adjustedNotes.length === 0 ||
        adjustedNotes[adjustedNotes.length - 1].number !== number
      ) {
        adjustedNotes.push({ number: number, length: subNote.length });
      } else {
        adjustedNotes[adjustedNotes.length - 1].length += subNote.length;
      }
    });
    console.log("adjusted", this.notes, this.subNotes, adjustedNotes);*/
    this.props.saveInput({ notes: this.notes, subNotes: this.subNotes });
  }

  updatePitch() {
    if (
      !this.analyserNode ||
      !this.detector ||
      !this.sampleRate ||
      !this.inputBuffer
    )
      return;

    this.analyserNode.getFloatTimeDomainData(this.inputBuffer);
    const pitch = this.detector.findPitch(this.inputBuffer, this.sampleRate);
    this.history.push(pitch);
    if (this.history.length > this.historyLength) {
      this.history.shift();
    }
  }

  processPitch() {
    const noteNames = [
      "c",
      "c#",
      "d",
      "eb",
      "e",
      "f",
      "f#",
      "g",
      "g#",
      "a",
      "bb",
      "b",
    ];
    //const noteNumbers = [1,1,2,2,3,4,4,5,5,6,7,7];
    const pitch = this.history[this.history.length - 1];
    if (Array.isArray(pitch) && pitch[1] >= 0.95) {
      const numberFloat = (Math.log(pitch[0] / 440.0) / Math.log(2)) * 12 + 69;
      const number = Math.round(numberFloat);
      const subNumber = Math.round(numberFloat * 5);
      if (
        this.subNotes.length === 0 ||
        this.subNotes[this.subNotes.length - 1].subNumber % 60 !==
          subNumber % 60
      ) {
        this.subNotes.push({ subNumber: subNumber, length: 1 });
      } else {
        this.subNotes[this.subNotes.length - 1].length++;
      }

      if (
        number >= this.minNumber &&
        (this.notes.length === 0 ||
          (this.notes[this.notes.length - 1].number % 12 !== number % 12 &&
            ++this.holdCount > 0))
      ) {
        this.holdCount = 0;
        this.notes.push({
          number: number,
          name: noteNames[number % 12],
          length: 1,
        });
        //this.props.addInput(noteNames[number % 12].substring(0,1));
        this.setState({
          searchString: this.notes.map((note) => note.name).join(" "),
        });
      } else if (this.notes.length > 0) {
        this.notes[this.notes.length - 1].length++;
      }
    }
  }

  setUpdatePitchInterval(interval) {
    if (this.intervalHandle !== undefined) {
      clearInterval(this.intervalHandle);
    }
    if (interval > 0) {
      this.intervalHandle = setInterval(() => {
        this.updatePitch();
        this.processPitch();
      }, interval);
    }
  }

  resetAudioContext() {
    this.sampleRate = this.analyserNode = this.inputBuffer = null;

    const audioContextOptions = {};
    if (this.overrideSampleRate) {
      audioContextOptions.sampleRate = this.desiredSampleRate;
    }
    this.audioContext = new AudioContext(audioContextOptions);
    this.sampleRate = this.audioContext.sampleRate;

    this.analyserNode = new AnalyserNode(this.audioContext, {
      fftSize: this.inputBufferSize,
    });
    this.audioContext
      .createMediaStreamSource(this.micStream)
      .connect(this.analyserNode);
    this.detector = PitchDetector.forFloat32Array(this.analyserNode.fftSize);
    this.inputBuffer = new Float32Array(this.detector.inputLength);
  }

  getAbc() {
    const abcNotes = this.notes
      .map(
        (note) =>
          this.getAbcNoteOctave(
            Math.floor(note.number / 12),
            this.getAbcNoteAccidential(note.name)
          ) + "0"
      )
      .join(" ");
    return `
%%abc-charset utf-8

X:1
M:none
L:1/4
K:C
${abcNotes ? abcNotes : "z1"} `;
  }

  getAbcNoteOctave(octave, noteName) {
    //log.debug('#####oc',octave)
    if (octave <= 5) {
      return noteName.toUpperCase() + ",".repeat(5 - octave);
    } else {
      return noteName.toLowerCase() + "'".repeat(octave - 6);
    }
  }

  getAbcNoteAccidential(note) {
    let accidential = note
      .substr(1)
      .replace("bb", "__")
      .replace("b", "_")
      .replace("#", "^")
      .replace("x", "^^");
    //if (accidential === '') accidential = '=';
    // get note root name
    return accidential + note.substr(0, 1);
  }

  render() {
    const abc = this.getAbc();
    return (
      <div>
        <div>
          <h2>Spela in</h2>
          <p>
            OBS! Inspelningsfunktionen är mer ett "proof of concept" i
            dagsläget. Den kräver en hel del vidareutveckling för att fungera
            tillfredsställande. Det fungerar inte så bra att sjunga, men fiol
            eller flöjt, spelat i ett långsamt tempo, kan ge hyfsade resultat.
          </p>
        </div>
        <Notation
          notation={abc}
          engraverParams={{
            responsive: "resize",
          }}
        />
        <span>{this.state.searchString}&nbsp;</span>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => this.toggleRecording()}
          fullWidth={true}
          style={{ marginBottom: "0.5rem" }}
        >
          {this.state.recording
            ? "Avsluta inspelning"
            : this.state.searchString && this.state.searchString.length
            ? "Fortsätt inspelning"
            : "Starta inspelning"}
        </Button>
        <Button
          color="default"
          variant="contained"
          onClick={() => this.clear()}
          fullWidth={true}
          style={{ marginBottom: "0.5rem" }}
          disabled={!this.state.searchString}
        >
          Rensa
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.close()}
          fullWidth={true}
          style={{ marginBottom: "0.5rem" }}
        >
          {this.state.searchString && this.state.searchString.length >= 5
            ? "Sök"
            : "Avbryt"}
        </Button>
      </div>
    );
  }
}
export default AudioInput;
