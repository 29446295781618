import { Component } from "react";
import AbcPreview from "./AbcPreview";

class SearchItem extends Component {
  render() {
    const { item, onClick, preview, pattern } = this.props;
    return (
      <div
        onClick={() => onClick(item)}
        data-id={item.id}
        style={{ marginBottom: "0.2rem" }}
      >
        {item.title} [
        {item.levenshtein !== undefined && pattern
          ? Math.round(
              ((pattern.length - item.levenshtein) * 100) / pattern.length
            ) + "%"
          : ""}
        ]
        {preview ? (
          <AbcPreview
            onClick={() => onClick(item)}
            id={item.id}
            abc={item.abc}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default SearchItem;
