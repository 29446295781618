import { Component } from "react";
import SearchItem from "./SearchItem";

class SearchResults extends Component {
  render() {
    return (
      <div>
        {this.props.items
          ? this.props.items.map((item) => (
              <SearchItem
                key={item.id}
                item={item}
                preview={this.props.abcPreview}
                pattern={this.props.searchString}
                onClick={(item) => this.props.showItem(item)}
              />
            ))
          : ""}
        {this.props.state === "searching" ? <div>Söker...</div> : ""}
      </div>
    );
  }
}

export default SearchResults;
