import { Component } from "react";
import Tune from "./Tune";
import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      state: "",
      items: [],
      item: null,
      abcPreview: true,
      searchString: null,
    };
  }

  componentDidMount() {}

  showItem(item, item2) {
    this.setState({ item: item });
  }

  render() {
    const { error, item, items, state, abcPreview, searchString } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (item) {
      return (
        <Tune
          tune={item}
          onClose={() => this.showItem(null)}
          searchSimilar={(id) => this.doSearch(id)}
        />
      );
    } else {
      return (
        <div>
          <SearchForm
            searchString={searchString}
            abcPreview={abcPreview}
            mode={this.props.mode}
            doSearch={(doSearch) => (this.doSearch = doSearch)}
            setState={(state) => this.setState({ state })}
            setItems={(items) => this.setState({ items: items })}
            setError={(error) => this.setState({ error: error })}
            setSearchString={(searchString) =>
              this.setState({ searchString: searchString })
            }
            setAbcPreview={(abcPreview) =>
              this.setState({ abcPreview: abcPreview })
            }
          />
          <SearchResults
            state={state}
            items={items}
            error={error}
            abcPreview={abcPreview}
            searchString={searchString}
            setItem={(item) => this.setState({ item: item })}
            showItem={(item) => this.showItem(item)}
          />
        </div>
      );
    }
  }
}

export default Search;
