import { Button } from "@material-ui/core";
import { Component } from "react";

class RootSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      root: props.root,
      roots: props.roots,
    };
  }

  setRoot = (root) => {
    if (this.state.roots) {
      const index = this.state.roots.indexOf(root);
      let roots = [...this.state.roots];
      if (index >= 0) {
        roots.splice(index, 1);
        if (roots.length === 0) roots.push("*");
      } else {
        if (roots[0] === "*") {
          roots[0] = root;
        } else if (root === "*") {
          roots = ["*"];
        } else {
          roots.push(root);
        }
      }
      this.setState({ roots: roots });
    } else {
      this.setState({ root: root });
      this.props.onClose(root);
    }
  };

  render() {
    const root = this.state.root ? [this.state.root] : this.state.roots;

    const roots = [
      "*",
      "c",
      "db",
      "d",
      "eb",
      "e",
      "f",
      "f#",
      "g",
      "ab",
      "a",
      "bb",
      "b",
    ];
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <div>{this.props.title ? this.props.title : "Grundton"}</div>
          <div>{this.props.description ? this.props.description : ""}</div>

          {roots.map((btnRoot) => {
            return (
              <Button
                key={btnRoot}
                color={root.indexOf(btnRoot) >= 0 ? "primary" : "secondary"}
                variant="contained"
                onClick={() => this.setRoot(btnRoot)}
              >
                {btnRoot}
              </Button>
            );
          })}
        </div>
        <div>
          <Button
            style={{ float: "right" }}
            color="primary"
            variant="contained"
            onClick={() => this.props.onClose(root)}
          >
            Spara
          </Button>
        </div>
      </div>
    );
  }
}

export default RootSelector;
